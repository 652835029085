body {
  margin: 0;
}

/**
 * Privacy & Cookies Notice
 */
.cookie-message {
  background-color: #efede6;
  width: 100vw;
  border-top: 1px solid #c5c3bf;
  box-sizing: border-box;
  color: #414042;
  font-family: 'Noto Sans', 'Helvetica Neue', Arial, freesans, sans-serif;
  font-size: 16px;
  padding: 30px;
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 960px) {
  .cookie-message {
    padding: 16px;
  }
}

#cookie-message__content {
  width: 100%;
  max-width: 1062px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}
@media screen and (max-width: 960px) {
  #cookie-message__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  }
}

.cookie-message__title {
  font-family: 'Noto Serif';
  font-size: 21px;
  font-weight: 600;
  max-width: 300px;
}
@media screen and (max-width: 960px) {
  .cookie-message__title {
    max-width: none;
    margin-bottom: 10px;
  }
}

.cookie-messages__body {
  max-width: 600px;
}
@media screen and (max-width: 960px) {
  .cookie-messages__body {
    max-width: none;
  }
}

.cookie-messages__body a {
  color: #414042;
  text-decoration: underline;
}
.cookie-messages__body a:hover {
  color: #414042;
}

.cookie-message__button {
  background-color: #efede6;
  border: 1px solid #414042;
  border-radius: 8px;
  color: #414042;
  font-size: 14px;
  font-weight: 600;
  padding: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.cookie-message__button:hover {
  background-color: #414042;
  color: #efede6;
}
