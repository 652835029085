.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background:#EFEDE6;
  overflow: hidden;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  
}
.swiper-slide img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px ;
  object-fit: contain;
 
}

.swiper-pagination-bullet-active{
    background-color: #DB7536 ;
  }

  .swiper-pagination-bullet{
    margin-left:2.5px !important;
    margin-right:2.5px !important;
 
  }
  .custom-pagination-div{
    display:flex;
    justify-content: center;
    padding: 20px ;
  }
  .swiper-button-next {
    background-image: url(./assets/buttonNextSwiper.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    color:transparent
  }
  
  .swiper-button-prev{
    background-image: url(./assets/buttonPrevSwiper.svg);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    color:transparent
  }